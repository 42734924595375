import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generic-support-for-ticket-media"
    }}>{`Generic support for Ticket media`}</h1>
    <p>{`The NOD platform can support a number of existing and future Ticket media. This support is generic so that what needs to be changed on the Ticket Media is determined by separate NOD Plugins with support for the necessary business logic.
NOD Platform today supports the following Ticket Media:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Nordic Specification DESFire (NSD) `}{`[HB V821.18]`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Media Independent Mobile 2D Barcode `}{`[HB V821.25]`}{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uktralight card `}{`[HB V821 - Part 18 App D]`}{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Paper ticket 2D Barcode `}{`[HB V821.25]`}</p>
      </li>
    </ul>
    <p>{`For NSD and ultralight, Medium is the physical travel card. For mobile tickets, Medium is an electronic image that can contain a QR code. For paper ticket, Medium is a QR code image that is written on paper.
All the above documents in brackets can be provided on request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      